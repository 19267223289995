import { mdiEmailOutline } from '@mdi/js'
import { subscription } from '@urql/svelte'
import { ftl, translate } from '/@/multilang'
import { jsFormatDateTime } from '/@/multilang/i18n.js'
import { jsFormatActor, parseISO } from '../../utils'
import { user } from '/@/store.js'

ftl`
  notification-mail = New mail
  notification-mail-pending-review = New mail pending
  `

let unsubscribe

function toastify(mail, idPrefix) {
  return {
    ...mail,
    id: `${idPrefix}#${mail.uuid}`,
    subtitle: jsFormatDateTime({ dt: parseISO(mail.sent_at) }),
    iconPath: mdiEmailOutline,
    requireInteraction: false,
    url: user.is_staff() ? `/mail/` : `/mail/view/${mail.uuid}`,
    body: jsFormatActor(mail.owner),
  }
}

/**
 * Inmate: New/Update of a mail
 */
function mail(notify, denotify) {
  unsubscribe?.()
  unsubscribe = subscription({
    query: `subscription { mailNotifications { opcode, mails {
      uuid, sent_at, folder, target_folder,
      owner {
        __typename,
        ... on Inmate {
            uuid,
            username,
            first_name,
            last_name
        }
      } } } }`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.mailNotifications) return

    // todo: make this work for more complex URLs like "/mail/view/uuiduiduiduiduiduid"
    // if (router.url().endsWith('/mail')) return

    let { opcode, mails } = e.data.mailNotifications

    mails = mails
      .map((m) => {
        const toastData = toastify(m, 'mail')

        return toastData
          ? {
              ...toastData,
              title: translate('notification-mail').text,
            }
          : null
      })
      .filter((o) => o)

    if (opcode == 'delete') {
      mails.forEach(denotify)
    } else {
      mails.forEach((m) => {
        if (m.folder == 'inbox') {
          notify(m)
        }
      })
    }
  })

  return () => {
    unsubscribe?.()
  }
}

/**
 * Staff: New mail to review
 */
function mailPendingReview(notify, denotify) {
  unsubscribe?.()
  unsubscribe = subscription({
    query: `subscription { mailUpdates {
      opcode, mails { uuid, sent_at, folder,
      owner {
        __typename,
        ... on Inmate {
            uuid,
            username,
            first_name,
            last_name
        }
      } } } }`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.mailUpdates) return
    // if (router.url().endsWith('/mail')) return

    let { opcode, mails } = e.data.mailUpdates

    mails = mails
      .map((m) => {
        const toastData = toastify(m, 'mailPendingReview')

        return toastData
          ? {
              ...toastData,
              title: translate('notification-mail-pending-review').text,
            }
          : null
      })
      .filter((o) => o)

    if (opcode == 'initial') return

    if (opcode == 'delete') {
      mails.forEach(denotify)
    } else {
      mails.forEach((m) => {
        if (m.folder == 'pending') {
          notify(m)
        }
      })
    }
  })

  return () => {
    unsubscribe?.()
  }
}

export { mail, mailPendingReview }
