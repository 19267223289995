import { subscription } from '@urql/svelte'
import { translate, ftl } from '/@/multilang'
import { router } from '/@/router.js'
import { mdiBell, mdiThumbsUpDown } from '@mdi/js'

ftl`
  notification-call-incoming = Incoming call
  staff-callsession-state-pending = Pending
  `

let unsubscribeCallIncoming, unsubscribeCallPending

/**
 * Calls back when an incoming call is ringing
 * @param {function} notify
 * @param {function} denotify
 * @returns
 */
function callIncoming(notify, denotify) {
  function toastify(call) {
    return {
      id: `call#${call.uuid}`,
      title: translate('notification-call-incoming').text,
      body: `${call.caller.__typename}: ${call.caller.username}`,
      subtitle: call.caller.uuid,
      iconPath: mdiBell,
      requireInteraction: true,
      url: `/videovisit/call/${call.caller.__typename}/${call.caller.uuid}`,
    }
  }

  let sessions = []

  unsubscribeCallIncoming?.()
  unsubscribeCallIncoming = subscription({
    query: `subscription { videovisitInbound {
      uuid, callStatus, caller {
        __typename,
        ... on Visitor {
          uuid,
          username,
        },
        ... on Inmate {
          uuid,
          username,
          first_name,
          last_name,
        },
        ... on Staff {
          uuid,
          username,
          first_name,
          last_name
        },
      } } }`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.videovisitInbound) return
    if (router.url().endsWith('/surveillance')) return

    const call = e.data.videovisitInbound

    if (call.callStatus != "ringing") {
      sessions = sessions.filter((ss) => ss.uuid != call.uuid)
      denotify(toastify(call))
    } else if (!sessions.find((ss) => ss.uuid == call.uuid)) {
      sessions.push(call)
      notify(toastify(call))
    }
  })

  return () => {
    unsubscribeCallIncoming?.()
  }
}

/**
 * Calls back when an incoming call is pending
 * @param {function} notify
 * @param {function} denotify
 * @returns
 */
function callPending(notify, denotify) {
  function toastify(sesh) {
    return {
      id: `sesh#${sesh.uuid}`,
      title: translate('staff-callsession-state-pending').text,
      body: `${sesh.caller.__typename}: ${sesh.caller.username}`,
      subtitle: `${sesh.callee.__typename}: ${sesh.callee.username}`,
      iconPath: mdiThumbsUpDown,
      requireInteraction: true,
      url: `/dashboard`,
    }
  }

  let sessions = []

  const Party = `{
    __typename,
    ... on Visitor {
      uuid,
      username,
    },
    ... on Inmate {
      uuid,
      username,
      first_name,
      last_name,
    },
    ... on Staff {
      uuid,
      username,
      first_name,
      last_name
    }
  }`
  unsubscribeCallPending?.()
  unsubscribeCallPending = subscription({
    query: `subscription { videovisitSessions {
      uuid, callStatus, caller ${Party}, callee ${Party}
      } }`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.videovisitSessions) return

    const sesh = e.data.videovisitSessions
    if (sesh.callStatus != 'pending') {
      sessions = sessions.filter((ss) => ss.uuid != sesh.uuid)
      denotify(toastify(sesh))
    } else if (!sessions.find((ss) => ss.uuid == sesh.uuid)) {
      sessions.push(sesh)
      notify(toastify(sesh))
    }
  })

  return () => {
    unsubscribeCallPending?.()
  }
}

export { callIncoming, callPending }
