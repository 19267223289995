import { query, subscription } from '@urql/svelte'
import { jsFormatDateTime } from '/@/multilang/i18n.js'
import { translate, ftl } from '/@/multilang'
import { mdiCalendarQuestion } from '@mdi/js'
import { parseISO } from '/@/utils.js'

ftl`
  notification-invitation = Pending invitation
  `

let unInvitation

function toastify(evt) {
  const dt = parseISO(evt.start)
  return {
    id: `invitation#${evt.uuid}`,
    expiry: dt,
    title: translate('notification-invitation').text,
    body: evt.title,
    subtitle: jsFormatDateTime({ dt }),
    iconPath: mdiCalendarQuestion,
    requireInteraction: true,
    url: `/calendar/?eid=${evt.uuid}`,
  }
}

function invitation(notify, denotify) {
  const handleInvitation = ({ operation, event }) => {
    const evt = toastify(event)

    if (
      operation != 'delete' &&
      !(parseISO(evt?.start) < new Date()) &&
      evt.response == 'pending'
    ) {
      notify(evt)
    } else {
      denotify(evt)
    }
  }

  query({
    query: `query { queryInvitations { uuid, start, title, response } }`,
    variables: { start: Date.now() },
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.queryInvitations) return

    e.data.queryInvitations.forEach((event) => handleInvitation({ event }))
  })

  unInvitation?.()
  unInvitation = subscription({
    query: `subscription { invitationUpdates {
      operation,
      event { uuid, start, title, response }
     } }`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.invitationUpdates) return

    handleInvitation(e.data.invitationUpdates)
  })

  return () => {
    unInvitation?.()
  }
}

export { invitation }
