import { browser_id, settings, user } from '/@/store.js'
import axios from 'axios'
import { detect } from 'detect-browser'
import { pack, record } from 'rrweb'
import { ErrorStackParser } from 'rrweb/es/rrweb/packages/rrweb/src/plugins/console/record/error-stack-parser.js'
import { stringify as rrweb_stringify } from 'rrweb/es/rrweb/packages/rrweb/src/plugins/console/record/stringify.js'
import { serializeError } from 'serialize-error';
import shortuuid from 'short-uuid'

let uuid
let evtapi
let identity

let storage_key = 'CC3_ClientSessionID'
const models = {
  inmate: 'Inmate',
  internal: 'Inmate',
  external: 'Inmate',
  staff: 'Staff',
  visitor: 'Visitor',
}

const stringifyOptions = {
  numOfKeysLimit: 500,
  depthOfLimit: 40,
}

const decycle = (object, replacer) => {
    var objects = new WeakMap();     // object to path mappings

    return (function derez(value, path) {
        var old_path;   // The path of an earlier occurance of value
        var nu;         // The new object or array

        if (value instanceof Error)
            value = serializeError(value)

        if (replacer !== undefined) {
            value = replacer(value);
        }

        if (
            typeof value === "object"
            && value !== null
            && !(value instanceof Boolean)
            && !(value instanceof Date)
            && !(value instanceof Number)
            && !(value instanceof RegExp)
            && !(value instanceof String)
        ) {

            old_path = objects.get(value);
            if (old_path !== undefined) {
                return { $ref: old_path };
            }

            objects.set(value, path);

            if (Array.isArray(value)) {
                nu = [];
                value.forEach(function (element, i) {
                    nu[i] = derez(element, path + "[" + i + "]");
                });
            } else {
                nu = {};
                Object.keys(value).forEach(function (name) {
                    nu[name] = derez(
                        value[name],
                        path + "[" + JSON.stringify(name) + "]"
                    );
                });
            }
            return nu;
        }
        return value;
    }(object, "$"));
}

const stringify = (obj, opts) => {
    return rrweb_stringify(decycle(obj), opts)
}

user.subscribe((current) => {
  if (current.uuid && current.uuid != uuid) {
    identity = {
      user_uuid: current.uuid,
      user_model: models[settings.context],
      payload: {
        ...detect(),
        username: current.username,
      },
    }

    if (evtapi) evtapi.post('/identity', identity).catch(() => {})
  }
  uuid = current.uuid
})

const defaultLogOptions = {
  level: [
    'assert',
    'clear',
    'count',
    'countReset',
    'debug',
    'dir',
    'dirxml',
    'error',
    'group',
    'groupCollapsed',
    'groupEnd',
    // 'info',
    'log',
    'table',
    'time',
    'timeEnd',
    'timeLog',
    'trace',
    'warn',
  ],
  lengthThreshold: 1000,
  logger: 'console',
}

let cancelHandlers = []

function _cancelRecorder() {
  console.info('recorder.cleanup', cancelHandlers.length)
  cancelHandlers.reverse().forEach((h) => h())
  cancelHandlers = []
}

export function recordSession() {
  _cancelRecorder()

  console.info('recorder.init')

  // fetch wrapper
  const { fetch: origFetch } = window
  cancelHandlers.push(() => {
    window.fetch = origFetch
  })
  window.fetch = async (resource, init) => {
    // don't record own recordings...
    if (resource.indexOf('/rrweb/record/') >= 0)
      return await origFetch(resource, init)

    let xhrlog = {
      eventType: 'network',
      method: init?.method || 'GET',
      url: resource,
      requestHeaders: init?.headers || {},
      requestBody: init?.body || null,
      responseHeaders: {},
      responseBody: null,
      status: null,
      statusText: null,
      timeStart: performance.now(),
      timeEnd: null,
      // payloadsCaptured: options.capturePayload || false
    }

    const response = await origFetch(resource, init)

    xhrlog.timeEnd = performance.now()
    xhrlog.duration = xhrlog.timeEnd - xhrlog.timeStart
    xhrlog.status = response.status
    xhrlog.statusText = response.statusText

    for (let pair of response.headers.entries()) {
      xhrlog.responseHeaders[pair[0]] = pair[1]
    }

    if (response.ok) {
      response
        .clone()
        .text()
        .then((body) =>
          record.addCustomEvent('network-event', {
            ...xhrlog,
            responseBody: body,
          })
        )
        .catch(() => {})
    } else {
      record.addCustomEvent('network-event', xhrlog)
    }

    return response
  }

  // XMLHttpRequest wrapper
  let oldOpen = XMLHttpRequest.prototype.open
  cancelHandlers.push(() => {
    XMLHttpRequest.prototype.open = oldOpen
  })
  XMLHttpRequest.prototype.open = function () {
    let that = this

    that.xhrlog = {
      eventType: 'network',
      method: null,
      url: null,
      requestHeaders: {},
      requestBody: null,
      responseHeaders: {},
      responseBody: null,
      status: null,
      statusText: null,
      timeStart: null,
      timeEnd: null,
      duration: null,
      // payloadsCaptured: options.capturePayload || false
    }

    that.xhrlog.method = arguments[0]
    that.xhrlog.url = arguments[1]

    // Capture headers set
    let oldSetRequestHeader = that.setRequestHeader
    that.setRequestHeader = function () {
      that.xhrlog.requestHeaders[arguments[0]] = arguments[1]
      oldSetRequestHeader.apply(this, arguments)
    }

    // Capture what is sent up
    let oldSend = that.send
    that.send = function () {
      if (arguments.length) {
        that.xhrlog.requestBody = arguments[0]
      }
      that.xhrlog.timeStart = performance.now()
      oldSend.apply(this, arguments)
    }

    // Assign an event listener
    this.addEventListener(
      'load',
      function () {
        that.xhrlog.responseBody = that.response
      },
      false
    )

    this.addEventListener(
      'loadend',
      function () {
        let headers = that.getAllResponseHeaders()
        let arr = headers.trim().split(/[\r\n]+/)
        arr.forEach(function (line) {
          let parts = line.split(': ')
          let header = parts.shift()
          let value = parts.join(': ')
          that.xhrlog.responseHeaders[header] = value
        })

        that.xhrlog.timeEnd = performance.now()
        that.xhrlog.duration = that.xhrlog.timeEnd - that.xhrlog.timeStart
        that.xhrlog.status = that.status
        that.xhrlog.statusText = that.statusText

        if (that.xhrlog.url.indexOf('/rrweb/record/') < 0)
          record.addCustomEvent('network-event', that.xhrlog)
      },
      false
    )

    oldOpen.apply(this, arguments)
  }

  // error handler
  const errorHandler = (event) => {
    let trace = ErrorStackParser.parse(event.error).map((stackFrame) =>
      stackFrame.toString()
    )
    let payload = [stringify(event.message, stringifyOptions)]
    record.addCustomEvent('console-event', {
      level: 'error',
      trace: trace,
      payload: payload,
    })
  }

  window.addEventListener('error', errorHandler)
  cancelHandlers.push(() => {
    window.removeEventListener('error', errorHandler)
  })

  // console.log wrapper
  const loggingHandler = (level, origFunc) => {
    let nested = false
    return (...args) => {
      if (nested) return
      nested = true
      origFunc.call(console, ...args)
      let trace = ErrorStackParser.parse(new Error())
        .map((stackFrame) => stackFrame.toString())
        .splice(1)
      let payload = args.map((s) => stringify(s, stringifyOptions))
      record.addCustomEvent('console-event', {
        level: level,
        trace: trace,
        payload: payload,
      })
      nested = false
    }
  }

  let logger = window.console
  for (let lvl of defaultLogOptions.level) {
    let origHandler = logger[lvl]
    if (origHandler) {
      cancelHandlers.push(() => {
        logger[lvl] = origHandler
      })
      logger[lvl] = loggingHandler(lvl, origHandler)
    }
  }

  let events = []

  let session_id = sessionStorage.getItem(storage_key)

  if (session_id === null) {
    session_id = shortuuid.generate()
    sessionStorage.setItem(storage_key, session_id)
  }

  evtapi = axios.create({
    baseURL: `${window.location.origin}/rrweb/record/${session_id}`,
    headers: { 'X-Browser-Identifier': browser_id },
    timeout: 10000,
  })

  function sendHelper(url, value, retries) {
    if (!retries) return
    evtapi?.post(url, value).catch(() => {
      sendHelper(url, value, retries - 1)
    })
  }

  if (uuid) sendHelper('/identity', identity, 5)

  function flushEvents() {
    if (events.length) sendHelper('/events', events, 5)
    events = []
  }

  let unload = record({
    emit(event) {
      events.push(btoa(event))
      if (events.length >= 512) flushEvents()
    },
    packFn: pack,
  })

  // save events every 10 seconds
  let timer = setInterval(flushEvents, 10 * 1000)

  cancelHandlers.push(() => {
    // force new session later on
    sessionStorage.removeItem(storage_key)
    clearInterval(timer)
    flushEvents()
    unload()
    evtapi = undefined
  })

  return _cancelRecorder
}
