import { mdiCalendarClock } from '@mdi/js'
import { query, subscription } from '@urql/svelte'
import { ftl, translate } from '/@/multilang'
import { jsFormatDateTime } from '/@/multilang/i18n.js'
import { parseISO } from '/@/utils.js'

ftl`
  notification-event-reminder = event reminder
  `

// nb milliseconds before the start of an event to trigger a reminder notification
const TIMESPAN_EVENT_REMINDER = 1000 * 60 * 15

// timespan to cover to gather incoming events. Refreshed every half of this time.
const TIMESPAN_EVENT_FETCH = 1000 * 60 * 60 * 12

let timeoutsEvent = {}

const destroyers = {}

function toastify(evt) {
  return {
    id: `eventReminder#${evt.uuid}`,
    evtUUID: evt.uuid,
    start: evt.start,
    title: translate('notification-event-reminder').text,
    body: evt.title,
    subtitle: jsFormatDateTime({ dt: evt.start }),
    // discard the notification after the event has started
    expiry: evt.start,
    iconPath: mdiCalendarClock,
    requireInteraction: true,
    url: `/calendar/?eid=${evt.uuid}`,
  }
}

/**
 * Calls back when event is soon starting
 * @param {function} notify
 * @param {function} denotify
 * @returns
 */
function eventReminder(notify, denotify) {
  const handleEvent = ({ event, operation }) => {
    event.start = parseISO(event.start)

    const toast = toastify(event)

    // create or update
    if (operation != 'delete' && event?.start > new Date()) {
      if (event?.response === 'declined') return

      setReminder(toast, notify, denotify)
    } else {
      // deleted or already started
      // clear timeouts
      const timeout = timeoutsEvent[event.uuid]
      if (timeout) clearTimeout(timeout)
      delete timeoutsEvent[event.uuid]

      // clear toasts
      denotify(toast)
    }
  }
  _query('queryCalendarScope', handleEvent, () => {
    _subscribe('calendarUpdatesScope', handleEvent)
  })

  _query('queryCalendarInmate', handleEvent, () => {
    _subscribe('calendarUpdatesInmate', handleEvent)
  })

  const unEventReminders = () => {
    for (let key in destroyers) {
      destroyers[key]()
      delete destroyers[key]
    }

    Object.values(timeoutsEvent).forEach(clearInterval)
    timeoutsEvent = {}
  }

  return unEventReminders
}

function _query(queryName, handleEvent, cb) {
  query({
    query: `query($start: DateTime, $end: DateTime) {
      ${queryName}(start: $start, end: $end) { uuid, start, title, response }
    }`,
    variables: {
      start: new Date(),
      end: new Date(Date.now() + TIMESPAN_EVENT_FETCH),
    },
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.[queryName]) return

    e.data[queryName].forEach((event) => handleEvent({ event }))

    cb?.(handleEvent)
  })

  setTimeout(() => {
    _query(queryName, handleEvent)
  }, TIMESPAN_EVENT_FETCH / 2)
}

function _subscribe(queryName, handleEvent) {
  destroyers[queryName]?.()
  destroyers[queryName] = subscription({
    query: `subscription{ ${queryName}{ operation, event{ uuid, start, title, response } } }`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.[queryName]) return

    handleEvent(e.data[queryName])
  })
}

function setReminder(toast, notify, denotify) {
  const msToStart = toast.start.getTime() - Date.now()

  if (msToStart > TIMESPAN_EVENT_REMINDER) {
    // `toast.start` is later than now + `TIMESPAN_EVENT_REMINDER`
    // make sure there's no active toast for this event
    denotify(toast)

    const delay = msToStart - TIMESPAN_EVENT_REMINDER

    timeoutsEvent[toast.id] = setTimeout(() => {
      notify(toast)
    }, delay)
  } else {
    // `toast.start` is inferior to `TIMESPAN_EVENT_REMINDER`
    // no timeout to wait for...
    const reminder = timeoutsEvent[toast.id]
    if (reminder) clearTimeout(reminder)
    delete timeoutsEvent[toast.id]
    // ...the notification has to be triggered ASAP
    notify(toast)
  }
}

export { eventReminder }
