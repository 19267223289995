<script>
  import { Panel, Button } from '/@/uielements'
  import { t, ftl } from '/@/multilang'
  import { mdiAlertOutline } from '@mdi/js'
  import { router } from '/@/router.js'
  import Icon from 'mdi-svelte'

  ftl`
    dialog-close = Close
    error-unhandled-title = Unhandled error
    error-unhandled-content = Sorry for the disruption, you may resume your normal activity
    `
</script>

<div class="w-screen h-screen flex flex-col items-center">
  <div class="mt-16 w-11/12 md:w-2/3 xl:w-1/3">
    <Panel>
      <div class="m-auto rounded-md shadow-2xl p-6">
        <div class="flex flex-col">
          <div class="flex flex-row justify-center text-primary-500">
            <span class="hidden md:block pr-6">
              <Icon path="{mdiAlertOutline}" size="6em" />
            </span>
            <h1 class="my-auto font-bold text-2xl text-primary-500">
              {$t('error-unhandled-title')}
            </h1>
          </div>

          <div class="m-auto p-6">{$t('error-unhandled-content')}</div>

          <Button
            text
            class="w-full p-4 pb-0"
            on:click="{() => (window.location = router.build('/'))}">
            {$t('dialog-close')}
          </Button>
        </div>
      </div>
    </Panel>
  </div>
</div>
