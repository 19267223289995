import { FluentBundle } from '@fluent/bundle'
import { FluentResource } from '@fluent/bundle'
import ftl_dedent from '@fluent/dedent'
import { negotiateLanguages } from '@fluent/langneg'
import { mapBundleSync } from '@fluent/sequence'
import { fluentResources } from '/@/locale'
import CountryLanguage from 'country-language'
import { endOfDay, startOfDay } from 'date-fns'
import { flatten, fromPairs, get, isString, keys, map } from 'lodash-es'
import { derived, writable } from 'svelte/store'

import { settings } from '../store.js'

import 'intl-pluralrules'
import 'proxy-polyfill'

// Aktivierte Locales
const configuredLocales = keys(fluentResources)

function locales_() {
  return configuredLocales.map((ll) => ({
    ...CountryLanguage.getLanguage(ll),
    locale: ll.toLowerCase(),
  }))
}

export const systemLocales = locales_()

// Store für die aktive Sprache
let bundles_ = null
let locale_ = null
const bundles = writable(null)
let dev_bundle = new FluentBundle('dev', { useIsolating: false })

const getBundle = derived(bundles, ($bundles) => (id) =>
  mapBundleSync($bundles, id)
)

export const locale = writable(null)

export const jsLocale = () => locale_

const languageMap = fromPairs([
  ...systemLocales.map((ll) => [ll.locale, ll.locale]),
  ...systemLocales.map((ll) => [ll.iso639_1, ll.locale]),
  ...flatten(
    systemLocales.map((ll) =>
      ll.langCultureMs.map((cc) => [
        cc.langCultureName.toLowerCase(),
        ll.locale,
      ])
    )
  ),
])

export function mapLanguage(lang, deflt = settings.defaultLocale) {
  if (!lang || lang === 'ftl') return lang
  if (lang === 'null') return ''
  if (isString(lang)) lang = lang.toLowerCase()
  return get(languageMap, lang, deflt)
}

export function setLocale(lcl) {
  if (!lcl) return

  if (isString(lcl)) lcl = [lcl]
  lcl = lcl.map((ll) => mapLanguage(ll, null)).filter((o) => o)
  const currentLocales = negotiateLanguages(lcl, keys(fluentResources), {
    defaultLocale: settings.defaultLocale,
  })

  let data = currentLocales.map((ll) => {
    const bundle = new FluentBundle(ll, { useIsolating: false })
    bundle.addResource(fluentResources[ll])
    return bundle
  })

  // eslint-disable-next-line no-undef
  if (import.meta.env.MODE === 'development') data.push(dev_bundle)

  bundles.set(data)
  bundles_ = data
  locale_ = currentLocales[0].toLowerCase()
  locale.set(locale_)
}

let ftl_debug_ = false
const ftl_debug = writable(false)

export const toggleFtlDebug = () => {
  ftl_debug_ = !ftl_debug_
  ftl_debug.set(ftl_debug_)
}

export const translate = (id, args) =>
  get_message((id) => mapBundleSync(bundles_, id), ftl_debug_, id, args)

const get_message = (flt, dbg, id, args) => {
  function proxy(cb) {
    return new Proxy(
      {},
      {
        get(target, name) {
          return cb(name)
        },
      }
    )
  }

  const bundle = id ? flt(id) : null
  const msg = bundle ? bundle.getMessage(id) : null
  const text = !msg
    ? `ftl|${id}`
    : dbg
    ? id
    : bundle.formatPattern(msg.value, args)
  const attrs = !(msg && msg.attributes)
    ? proxy((name) => `ftl|${id}.${name}`)
    : dbg
    ? proxy((name) => `${id}.${name}`)
    : fromPairs(
        map(msg.attributes, (pattern, name) => [
          name,
          bundle.formatPattern(pattern, args),
        ])
      )
  return { text, attrs }
}

export const t = derived(
  [getBundle, ftl_debug],
  ([$getBundle, $ftl_debug]) => (id, args) =>
    get_message($getBundle, $ftl_debug, id, args).text
)

export const translated = derived(
  [getBundle, ftl_debug],
  ([$getBundle, $ftl_debug]) => (id, args) =>
    get_message($getBundle, $ftl_debug, id, args)
)

export function ftl(text) {
  // eslint-disable-next-line no-undef
  if (import.meta.env.MODE === 'development')
    dev_bundle.addResource(new FluentResource(ftl_dedent(text)), {
      allowOverrides: true,
    })
}

// FIXME: an configuredLocales anpassen...
import { datefnsLocales } from '/@/locale/index.js'
import { format, formatDuration as formatDuration_, parseISO } from 'date-fns'

export const jsDateOptions = (lcl = locale_) => ({
  locale: datefnsLocales[lcl],
})
export const dateOptions = derived(locale, ($locale) => jsDateOptions($locale))

const DefaultDateFormatString = 'P'
const DefaultTimeFormatString = 'p'
const DefaultDateTimeFormatString = 'Pp'

// see formats: https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
export const jsFormatDate = ({
  dt,
  fmt = DefaultDateFormatString,
  undef = '',
  lcl = locale_,
}) => {
  if (!dt) return undef
  if (!(dt instanceof Date)) dt = parseISO(dt)

  return format(dt, fmt, {
    locale: datefnsLocales[lcl],
  })
}
export const formatDate = derived(locale, ($locale) => (dt, fmt, undef) =>
  jsFormatDate({ dt, fmt, undef, lcl: $locale })
)

export const jsFormatTime = ({
  dt,
  fmt = DefaultTimeFormatString,
  undef,
  lcl,
}) => jsFormatDate({ dt, fmt, undef, lcl })
export const formatTime = derived(locale, ($locale) => (dt, fmt, undef) =>
  jsFormatTime({ dt, undef, fmt, lcl: $locale })
)

export const jsFormatDateTime = ({
  dt,
  fmt = DefaultDateTimeFormatString,
  undef,
  lcl,
}) => jsFormatDate({ dt, fmt, undef, lcl })
export const formatDateTime = derived(locale, ($locale) => (dt, fmt, undef) => {
  return jsFormatDateTime({ dt, undef, fmt, lcl: $locale })
})

export const formatDuration = derived(locale, ($locale) => (dur, options) => {
  options = options || {}
  options = {
    locale: datefnsLocales[$locale],
    ...options,
  }
  return formatDuration_(dur, options)
})

export const formatValidity = derived(
  [getBundle, ftl_debug],
  ([$getBundle, $ftl_debug]) => (data) => {
    if (!data) return ''

    const { validity_start, validity_end, validity_user } = data
    const stringBuilder = []

    const translate = (txt, args) =>
      get_message($getBundle, $ftl_debug, txt, args).text

    // User type
    const t0 = {
      inmate: 'staff-document-validity-inmate',
      staff: 'staff-document-validity-staff',
    }[validity_user]
    if (t0) stringBuilder.push(translate(t0))

    // Dates
    const now = endOfDay(new Date())

    const dStart = validity_start && startOfDay(validity_start)
    const dEnd = validity_end && endOfDay(validity_end)

    if (dStart || dEnd) {
      if (dEnd && dEnd < now)
        stringBuilder.push(translate('timespan-validity-expired'))
      else if (dStart && dStart > now)
        stringBuilder.push(translate('timespan-validity-later'))
      else
        stringBuilder.push(
          translate(
            'timespan-validity-' +
              (dStart ? (dEnd ? 'spans' : 'starts') : 'ends'),
            {
              start: jsFormatDate({ dt: dStart, fmt: DefaultDateFormatString }),
              end: jsFormatDate({ dt: dEnd, fmt: DefaultDateFormatString }),
            }
          )
        )
    }

    // Scopes
    // todo

    return stringBuilder.join('  –  ')
  }
)

export function prettyLanguage(lang) {
  if (!lang) return lang
  const loc = systemLocales.find((ll) => ll.locale == lang)
  return loc.nativeName[0] || loc.name
}

// timeago
import TimeAgoBase from 'javascript-time-ago'
import { timeagoLocales } from '/@/locale/index.js'

const taMap = fromPairs(systemLocales.map((ll) => [ll.locale, ll.iso639_1]))
const taCache = {}

const makeTimeago = (lcl) => {
  TimeAgoBase.addLocale(timeagoLocales[lcl])
  return new TimeAgoBase(taMap[lcl])
}

const TimeAgo = (lcl) => {
  if (!(lcl in taCache)) taCache[lcl] = makeTimeago(lcl)

  return taCache[lcl]
}

export { TimeAgo }
