import { subscription } from '@urql/svelte'
import { translate, ftl } from '/@/multilang'
import { mdiWebcam } from '@mdi/js'

ftl`
  notification-pendingcontact = Pending contact
  `

let unPendingContact

let nbPendingContacts = null

function pendingContact(notify, denotify) {
  unPendingContact?.()
  unPendingContact = subscription({
    query: `subscription{videovisitPendingContacts{uuid}}`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.videovisitPendingContacts) return

    const newNumber = +e.data.videovisitPendingContacts.length

    if (!newNumber) {
      denotify({
        id: `pendingcontact`,
      })
    } else if (!nbPendingContacts) {
      // We only want to notify when getting the first contact request
      notify({
        id: `pendingcontact`,
        title: translate('notification-pendingcontact').text,
        iconPath: mdiWebcam,
        url: '/videovisit/whitelist-approval',
      })
    }

    nbPendingContacts = newNumber
  })

  return () => {
    unPendingContact?.()
  }
}

export { pendingContact }
