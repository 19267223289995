<script context="module">
  import { get, fromPairs } from 'lodash-es'
  import { systemLocales } from './i18n.js'
  import { localeFlags } from '/@/locale'

  const flags = fromPairs(
    systemLocales.map((cc) => {
      return [
        cc.locale,
        {
          flag: get(localeFlags, cc.locale),
          nativeName: cc.nativeName[0] || cc.name,
          name: cc.name,
          // ...
        },
      ]
    })
  )
</script>

<script>
  import { toPairs } from 'lodash-es'
  import { locale } from './i18n.js'
  import { createEventDispatcher } from 'svelte'
  // import './babelstoneflags.css'

  export let lang

  let showMenu = false
  let flag = get(flags, lang, {})
  const dispatch = createEventDispatcher()

  $: fontStyle = `font-size: 2.5rem;`

  $: setLocale(lang || $locale)

  const setLocale = (ll) => {
    lang = ll
    flag = get(flags, lang, {})
    dispatch('change', ll)
  }
</script>

<div
  class="cursor-pointer relative"
  title="{flag?.nativeName}"
  on:click="{() => (showMenu = !showMenu)}">
  <div style="{fontStyle}">
    {flag?.flag}
  </div>
  {#if showMenu}
    <div
      class="z-10 absolute right-0 mt-2 py-1 bg-white border rounded-lg
      shadow-xl">
      <div class="relative flex flex-row">
        {#each toPairs(flags) as ff}
          <button
            class="focus:outline-none px-1"
            title="{ff[1].nativeName}"
            on:click="{() => setLocale(ff[0])}">
            <div style="{fontStyle}">
              {ff[1].flag}
            </div>
          </button>
        {/each}
      </div>
    </div>
  {/if}
</div>
