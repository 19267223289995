import ftl_fin from './fi/prisec.ftl'
import ta_fin from 'javascript-time-ago/locale/fi'
import { fi as date_fin } from 'date-fns/locale'
import ftl_swe from './sv-SE/prisec.ftl'
import ta_swe from 'javascript-time-ago/locale/sv'
import { sv as date_swe } from 'date-fns/locale'
import ftl_eng from './en-GB/prisec.ftl'
import ta_eng from 'javascript-time-ago/locale/en'
import { enGB as date_eng } from 'date-fns/locale'
import ftl_deu from './de/prisec.ftl'
import ta_deu from 'javascript-time-ago/locale/de'
import { de as date_deu } from 'date-fns/locale'
import ftl_est from './et/prisec.ftl'
import ta_est from 'javascript-time-ago/locale/et'
import { et as date_est } from 'date-fns/locale'
import ftl_spa from './es/prisec.ftl'
import ta_spa from 'javascript-time-ago/locale/es'
import { es as date_spa } from 'date-fns/locale'
import ftl_fra from './fr/prisec.ftl'
import ta_fra from 'javascript-time-ago/locale/fr'
import { fr as date_fra } from 'date-fns/locale'
import ftl_rus from './ru/prisec.ftl'
import ta_rus from 'javascript-time-ago/locale/ru'
import { ru as date_rus } from 'date-fns/locale'
import ftl_ron from './ro/prisec.ftl'
import ta_ron from 'javascript-time-ago/locale/ro'
import { ro as date_ron } from 'date-fns/locale'
export const fluentResources = {
  fin: ftl_fin,
  swe: ftl_swe,
  eng: ftl_eng,
  deu: ftl_deu,
  est: ftl_est,
  spa: ftl_spa,
  fra: ftl_fra,
  rus: ftl_rus,
  ron: ftl_ron,
}
export const timeagoLocales = {
  fin: ta_fin,
  swe: ta_swe,
  eng: ta_eng,
  deu: ta_deu,
  est: ta_est,
  spa: ta_spa,
  fra: ta_fra,
  rus: ta_rus,
  ron: ta_ron,
}
export const localeFlags = {
  fin: '\u{1f1eb}\u{1f1ee}',
  swe: '\u{1f1f8}\u{1f1fb}',
  eng: '\u{1f1ec}\u{1f1e7}',
  deu: '\u{1f1e9}\u{1f1ea}',
  est: '\u{1f1ea}\u{1f1f9}',
  spa: '\u{1f1ea}\u{1f1f8}',
  fra: '\u{1f1eb}\u{1f1f7}',
  rus: '\u{1f1f7}\u{1f1fa}',
  ron: '\u{1f1f7}\u{1f1f4}',
}
export const datefnsLocales = {
  fin: date_fin,
  swe: date_swe,
  eng: date_eng,
  deu: date_deu,
  est: date_est,
  spa: date_spa,
  fra: date_fra,
  rus: date_rus,
  ron: date_ron,
}
