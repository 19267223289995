import { subscription } from '@urql/svelte'
import {
  translate,
  ftl,
  parse_localized,
  jsLocalize,
  jsFormatDateTime,
} from '/@/multilang'
import { parseISO } from '/@/utils.js'
import { mdiFileDocumentOutline } from '@mdi/js'

ftl`
  notification-request = Request
  notification-request-pending = New request
  `

function toastify(rqt, idPrefix) {
  return {
    id: `${idPrefix}#${rqt.uuid}`,
    title: translate('notification-request-pending').text,
    body: jsLocalize(parse_localized(rqt.form_title)),
    subtitle: jsFormatDateTime({ dt: parseISO(rqt.submitted_at) }),
    iconPath: mdiFileDocumentOutline,
    requireInteraction: false,
    url: `/requests/notification/${rqt.uuid}`,
  }
}

let unsubscribeRequest

/**
 * Calls back when an inmate request is "completed"
 * @param {function} notify
 * @param {function} denotify
 * @returns
 */
function request(notify) {
  unsubscribeRequest?.()
  unsubscribeRequest = subscription({
    query: `subscription { inmateRequests { uuid, form_title, submitted_at, state } }`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.inmateRequests) return

    const rqt = e.data.inmateRequests

    if (['Approved', 'Rejected', 'Responded'].includes(rqt.state))
      notify(toastify(rqt, 'request'))
  })

  return () => {
    unsubscribeRequest?.()
  }
}

let unsubscribePending

/**
 * Calls back when a new inmate request is submitted
 * @param {function} notify
 * @param {function} denotify
 * @returns
 */
function requestPending(notify) {
  unsubscribePending?.()
  unsubscribePending = subscription({
    query: `subscription { staffRequests { uuid, form_title, submitted_at, state, closed_at } }`,
    requestPolicy: 'cache-and-network',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.staffRequests) return

    const rqt = e.data.staffRequests

    if (!rqt.closed_at && ['Submitted', 'InProgress'].includes(rqt.state))
      notify(toastify(rqt, 'requestPending'))
  })
  return () => {
    unsubscribePending?.()
  }
}

export { request, requestPending }
