<script>
  import { mdiAlertCircle, mdiClose, mdiCloseThick } from '@mdi/js'
  import { subscription } from '@urql/svelte'
  import { differenceInMilliseconds } from 'date-fns'
  import Icon from 'mdi-svelte'
  import { AudioContext } from 'standardized-audio-context'
  import { onMount, onDestroy } from 'svelte'
  import { slide } from 'svelte/transition'
  import { router } from '/@/router.js'
  import { user } from '/@/store.js'
  import { t, ftl } from '/@/multilang'
  import { debounce } from '/@/utils.js'
  import { getAvailableNotifications } from './'

  ftl`
    test-notification-title = PriSec
    test-notification-subtitle = notification
    test-notification-body = test
    `

  // window.Notification
  let hasNotification = null
  // window.AudioContext
  let hasAudio = null

  // in-app notification
  let audioCtx, audioBuffer, audioSource

  // list of active in-app notifications
  let inApps = []
  // list of active native notifications
  let natives = []

  const TOAST_DURATION = 10000

  const configDefault = 'toast'

  let subscribers = []

  let destroyers = {}

  onMount(() => {
    return subscription({
      query: `subscription { testNotifications }`,
    }).subscribe((e) => {
      if (e.error) return console.error(e)
      if (!e?.data?.testNotifications) return

      notify(
        { config: 'toast' },
        {
          title: $t('test-notification-title'),
          subtitle: $t('test-notification-subtitle'),
          body: $t('test-notification-body'),
          iconPath: mdiAlertCircle,
        }
      )
    })
  })

  onDestroy(() => {
    Object.values(destroyers).forEach((d) => d?.())
    audioSource?.stop()
    audioSource?.disconnect()

    audioCtx?.close()

    audioSource = null
    audioBuffer = null
    audioCtx = null
    hasAudio = null
  })

  $: init($user)

  const init = (user) => {
    subscribers = getAvailableNotifications()?.filter(({ permission }) =>
      user.has_permission(permission)
    )

    if (!subscribers?.length) return

    subscribers.forEach((source) => {
      const cfg = user.notifications?.details?.find(
        ({ name }) => name == source.name
      )
      source.config = cfg?.value ?? configDefault

      if (['toast', 'toast_silent'].includes(source.config)) {
        if (!destroyers[source.name]) {
          destroyers[source.name] = source.subscribe?.(
            (toast) => notify(source, toast),
            denotify
          )
        }
      } else {
        destroyers[source.name]?.()
        delete destroyers[source.name]
      }
    })
  }

  function notify(source, toast) {
    if ($user.notifications?.native === true) {
      triggerNative(source, toast)
    } else {
      triggerInApp(source, toast)
    }
  }

  function denotify(toast) {
    closeNative(toast)
    closeInApp(toast)
  }

  async function triggerInApp(source, toast) {
    closeInApp(toast)
    // pop the toast
    inApps = [...inApps, toast]

    // in-app sound
    // todo: if(toast.requireInteraction === true){ playSound once } else { playSound forever } ?
    if (source.config === 'toast') await playSound()

    if (toast.requireInteraction) return

    // automatically discard the toast
    const discardAfter = toast.expiry
      ? differenceInMilliseconds(toast.expiry, new Date())
      : TOAST_DURATION

    setTimeout(() => {
      closeInApp(toast)
    }, discardAfter)
  }

  async function triggerNative(source, toast) {
    if (hasNotification === null) {
      if (!('Notification' in window) || Notification.permission == 'denied') {
        hasNotification = false
      } else if (Notification.permission == 'granted') {
        hasNotification = true
      } else
        hasNotification = (await Notification.requestPermission()) == 'granted'
    }

    if (!hasNotification) return

    const nat = new Notification(toast.title, {
      body: toast.body,
      icon: `/files/favicon.png`,
      badge: `/files/favicon.png`,
      requireInteraction: toast.requireInteraction === true,
      silent: source.config !== 'toast',
    })

    nat.addEventListener('close', (e) => {
      closeNative(toast)
    })
    //.addEventListener("click", (e) => {})
  }

  const closeInApp = (toast) => {
    const inst = inApps.find(({ id }) => id == toast.id)
    if (!inst) return
    inApps = inApps.filter(({ id }) => id != toast.id)
  }

  const closeNative = (toast) => {
    const inst = natives.find(({ id }) => id == toast.id)
    if (!inst) return
    inst.close()
    natives = natives.filter(({ id }) => id !== toast.id)
  }

  async function playSound() {
    if (hasAudio === null) {
      try {
        // avoid doing this in parallel...
        hasAudio = false

        audioCtx = new AudioContext()

        audioBuffer = await fetch('/files/notification.mp3')
        audioBuffer = await audioBuffer.arrayBuffer()
        audioBuffer = await audioCtx.decodeAudioData(audioBuffer)

        hasAudio = true
      } catch (err) {
        hasAudio = false
        console.error(err)
      }
    }

    if (!hasAudio) return

    try {
      if (audioCtx.state === 'suspended') audioCtx.resume()

      audioSource?.stop()
      audioSource?.disconnect()

      audioSource = audioCtx.createBufferSource()
      audioSource.buffer = audioBuffer
      audioSource.connect(audioCtx.destination)
      audioSource?.start(0)
    } catch (e) {
      console.error(e)
    }
  }

  const onClick = (toast) => {
    if (toast.url) {
      router.goto(toast.url)
      denotify(toast)
    }
  }
</script>

<!-- @component
  Orchestrate the notification/alerting.
  It follows the notifications settings from the user's profile.
  It subscribes to all GraphQL updates it needs via JS modules like `Notification/EventReminder.js`, `Notification/Event.js`, `Notification/ChatMessage.js`
-->
<div
  class="notification absolute z-20 right-0 px-2"
  style="width: 24em; max-width: 90%;">
  {#each inApps as toast (toast.id)}
    <div
      transition:slide|local="{{ duration: 100, y: -50 }}"
      class="my-2 w-full overflow-x-hidden rounded shadow-lg"
      class:cursor-pointer="{toast.url}"
      on:click="{debounce(onClick, toast)}">
      <div class="flex items-stretch border border-primary-500 bg-primary-500">
        {#if toast.iconPath}
          <div class="p-2 bg-transparent text-primary-500">
            <Icon path="{toast.iconPath}" size="1.5" color="white" />
          </div>
        {/if}
        <div class="p-4 flex-grow self-center rounded bg-white">
          <div class="">{toast.title || 'PriSec'}</div>
          {#if toast.body}<div class="font-bold">{toast.body}</div>{/if}
          {#if toast.subtitle}<div class="text-secondary-500">
              {toast.subtitle}
            </div>{/if}
        </div>
        <div
          class="absolute right-0 mr-2 p-1 text-secondary-500 cursor-pointer hover:bg-primary-transLight"
          on:click|stopPropagation="{() => closeInApp(toast)}">
          <Icon
            path="{toast.requireInteraction ? mdiCloseThick : mdiClose}"
            size=".75" />
        </div>
      </div>
    </div>
  {/each}
</div>
