import { active, Route, router as tinro_router } from 'tinro'
import { tick } from 'svelte'
import { map } from 'lodash-es'

// prefix handling
function _router_store() {
  let url_prefix = ''

  function url_(url) {
    // const route = getContext('tinro')
    // let path = route.pattern
    // map(route.params, (v, k) => (path = path.replace(`:${k}`, v)))

    let path = window.location.pathname

    if (!url) return path

    // absolute url: url_prefix davor, relative url: aktuellen Pfad davor
    if (url[0] === '/') url = url_prefix + url
    else url = path + '/' + url

    // normalisieren
    let res = []
    map(url.split('/'), (pp) => {
      if (pp == '..') res.pop()
      else if (pp != '.' && pp != '') res.push(pp)
    })
    return '/' + res.join('/')
  }

  let isDirty = false

  return {
    ...tinro_router,

    needsReload: (latestRev) => {
      isDirty = isDirty || (import.meta.env.MODE === 'production' && !!latestRev && latestRev !== import.meta.env.GIT_REV_SHORT)
    },

    handleReload: () => {
      if (isDirty) {
        isDirty = false
        window.location.reload()
      }
    },

    goto: (url) => {
      tick().then(() => tinro_router.goto(url_(url)))
    },
    goto_: (url) => tick().then(() => tinro_router.goto(url)),

    set_url_prefix: (prf) => {
      url_prefix = prf
    },

    url: url_,

    build: (url) => {
      const prefix = url_prefix ? `${url_prefix}` : ''
      return `${window.location.origin}${prefix}${url}`
    },
  }
}

export const router = _router_store()

export { Route, active }
