import { map, filter, get, join, uniq, omit, includes } from 'lodash-es'

const noDepth = ['white', 'black', 'transparent']

function getClass(prop, color, depth, defaultDepth) {
  if (noDepth.includes(color)) return `${prop}-${color}`
  return `${prop}-${color}-${depth || defaultDepth} `
}

export default function utils(color, defaultDepth = 500) {
  return {
    bg: (depth) => getClass('bg', color, depth, defaultDepth),
    border: (depth) => getClass('border', color, depth, defaultDepth),
    txt: (depth) => getClass('text', color, depth, defaultDepth),
    caret: (depth) => getClass('caret', color, depth, defaultDepth),
  }
}

export function ClassBuilder(classes, defaultClasses) {
  let data =
    (typeof classes === 'function' ? classes(defaultClasses) : classes) ||
    defaultClasses

  let obj = {
    data: data ? data.split(' ') : [],

    get: () => {
      return join(uniq(filter(obj.data, (dd) => dd)), ' ')
    },

    extend: () => {
      return obj /* wtf? */
    },

    replace: (classes, cond = true) => {
      if (cond && classes)
        obj.data = map(obj.data, (cls) => get(classes, cls, cls))
      return obj
    },

    remove: (classes, cond = true) => {
      if (cond && classes) {
        const val = classes.split(' ')
        obj.data = filter(obj.data, (cls) => !includes(val, cls))
      }
      return obj
    },

    add: (className, cond = true, defaultValue) => {
      if (!cond || !className) return obj

      switch (typeof className) {
        case 'string':
        default:
          obj.data.push(...className.split(' '))
          break

        case 'function':
          obj.data.push(...className(defaultValue || obj.get()).split(' '))
          break
      }

      return obj
    },
  }
  return obj
}

const defaultReserved = ['class', 'add', 'remove', 'replace', 'value']

// export function filterProps(reserved, props) {
//   const r = [...reserved, ...defaultReserved]
//   return filter(props, pp => !r.includes(pp))
// }

export function filterProps(reserved, props) {
  const r = [...reserved, ...defaultReserved]

  return Object.keys(props).reduce(
    (acc, cur) =>
      cur.includes('$$') || cur.includes('Class') || r.includes(cur)
        ? acc
        : { ...acc, [cur]: props[cur] },
    {}
  )
}
