<script>
  import Icon from './Icon.svelte'
  import utils, { ClassBuilder, filterProps } from './classes.js'
  import createRipple from './ripple.js'
  import { Stretch } from 'svelte-loading-spinners'

  export let value = false
  export let outlined = false
  export let text = false
  export let block = false
  export let disabled = false
  // TODO make it show a spinner or something:
  export let mutating = false
  export let icon = null
  export let compact = false
  export let light = false
  export let dark = false
  export let flat = false
  export let iconClass = ''
  export let color = 'primary'
  export let href = null
  export let fab = false

  export let remove = ''
  export let add = ''
  export let replace = {}

  const classesDefault =
    'py-2 px-4 uppercase text-sm font-medium relative overflow-hidden'
  const basicDefault = 'text-white duration-200 ease-in'
  const outlinedDefault = 'bg-transparent border border-solid'
  const textDefault = 'bg-transparent border-none px-4 hover:bg-transparent'
  const iconDefault = 'p-4 flex items-center select-none'
  const fabDefault = 'hover:bg-transparent'
  const smallDefault = 'pt-1 pb-1 pl-2 pr-2 text-xs'
  const disabledDefault =
    'bg-gray-300 text-gray-500 dark:bg-dark-400 shadow-none pointer-events-none hover:bg-gray-300 cursor-default'
  const elevationDefault = 'hover:shadow-lg shadow-md'

  export let classes = classesDefault
  export let basicClasses = basicDefault
  export let outlinedClasses = outlinedDefault
  export let textClasses = textDefault
  export let iconClasses = iconDefault
  export let fabClasses = fabDefault
  export let smallClasses = smallDefault
  export let disabledClasses = disabledDefault
  export let elevationClasses = elevationDefault

  $: fab_ = fab || (text && icon)
  $: basic = !outlined && !text && !fab_
  $: elevation = (basic || icon) && !(disabled || mutating) && !flat && !text

  $: shade = dark ? -400 : light ? 200 : 0
  $: normal = 500 - shade
  $: lighter = 400 - shade

  $: ({ bg, border, txt } = utils(color))

  $: bClasses = ClassBuilder(classes, classesDefault)
    .add(basicClasses, basic, basicDefault)
    .add(`${bg(normal)} hover:${bg(lighter)}`, basic)
    .add(elevationClasses, elevation, elevationDefault)
    .add(outlinedClasses, outlined, outlinedDefault)
    .add(
      `${border(lighter)} ${txt(normal)} hover:${bg('trans')} dark-hover:${bg(
        'transDark'
      )}`,
      outlined
    )
    .add(`${txt(lighter)}`, text)
    .add(textClasses, text, textDefault)
    .add(iconClasses, icon, iconDefault)
    .remove('py-2', icon)
    .remove(txt(lighter), fab_)
    .add(disabledClasses, disabled || mutating, disabledDefault)
    .add(smallClasses, compact, smallDefault)
    .add('flex items-center justify-center h-8 w-8', compact && icon)
    .add('border-solid', outlined)
    .add('rounded-full', icon)
    .add('w-full', block)
    .add('rounded', basic || outlined || text)
    .add('button', !icon)
    .add(fabClasses, fab_, fabDefault)
    .add(`hover:${bg('transLight')}`, fab_)
    .add($$props.class)
    .remove(remove)
    .replace(replace)
    .add(add)
    .get()

  $: iClasses = icon
    ? ClassBuilder(iconClass)
        .add(txt(), fab_ && !iconClass)
        .get()
    : ''

  $: ripple = createRipple(text || fab_ || outlined ? color : 'white')

  $: props = filterProps(
    [
      'outlined',
      'text',
      'color',
      'block',
      'disabled',
      'mutating',
      'icon',
      'compact',
      'light',
      'dark',
      'flat',
      'add',
      'remove',
      'replace',
    ],
    $$props
  )
</script>

{#if href}
  <a href="{href}" {...props}>
    <button
      use:ripple
      class="{bClasses}"
      {...props}
      disabled="{disabled || mutating || null}"
      on:click="{() => (value = !value)}"
      on:click
      on:focus
      on:mouseover>
      {#if icon}
        <Icon
          class="{iClasses}"
          size="{compact ? '.5em' : '1em'}"
          path="{icon}" />
      {/if}
      <slot />
      {#if mutating}
        <div
          class="absolute top-0 left-0 w-full h-full bg-white text-gray-500 p-2">
          <Stretch color="currentColor" size="25" />
        </div>
      {/if}
    </button>
  </a>
{:else}
  <button
    use:ripple
    class="{bClasses}"
    {...props}
    disabled="{disabled || mutating || null}"
    on:click="{() => (value = !value)}"
    on:click
    on:focus
    on:mouseover>
    {#if icon}
      <Icon
        class="{iClasses}"
        size="{compact ? '.75em' : '1.5em'}"
        path="{icon}" />
    {/if}
    <slot />
    {#if mutating}
      <div
        class="absolute top-0 left-0 w-full h-full bg-white text-gray-500 p-2">
        <Stretch color="currentColor" size="25" />
      </div>
    {/if}
  </button>
{/if}
