import { mdiMessageTextOutline } from '@mdi/js'
import { subscription } from '@urql/svelte'
import { ftl, translate } from '/@/multilang'
import { jsFormatDateTime } from '/@/multilang/i18n.js'
import { router } from '/@/router.js'
import { user } from '/@/store.js'
import { jsFormatActor, parseISO } from '../../utils'

ftl`
  notification-chatmessage = Message
  `

let unsubscribe

/**
 * Calls back when a new chat message is received
 * @param {function} notify
 * @param {function} denotify
 * @returns
 */
function chatMessage(notify) {
  unsubscribe?.()
  unsubscribe = subscription({
    query: `subscription { latestMessages { opcode, uuid, timestamp, content, forwardContent, chat{ uuid,
      staff { uuid, username, first_name, last_name },
      inmate { uuid, inmate_id, username, first_name, last_name } } } }`,
    requestPolicy: 'cache-and-network',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.latestMessages) return
    if (router.url().endsWith('/chat')) return

    const msg = e.data.latestMessages

    if (msg.opcode == 'initial') return

    const theOtherOne = user.is_staff() ? msg.chat.inmate : msg.chat.staff
    const msgContent =
      user.is_staff() && msg.forwardContent ? msg.forwardContent : msg.content

    notify({
      id: `chatMessage#${msg.uuid}`,
      title: translate('notification-chatmessage').text,
      body: `${jsFormatActor(msg.chat.staff || msg.chat.inmate)}${
        msgContent ? `: ${msgContent}` : ''
      }`,
      subtitle: jsFormatDateTime({ dt: parseISO(msg.timestamp) }),
      iconPath: mdiMessageTextOutline,
      requireInteraction: false,
      url: `/chat/${theOtherOne.uuid}`,
    })
  })

  return () => {
    unsubscribe?.()
  }
}

export { chatMessage }
