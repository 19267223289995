import { subscription } from '@urql/svelte'
import { parseISO } from '/@/utils.js'
import { jsFormatDateTime } from '/@/multilang/i18n.js'
import { translate, ftl, parse_localized, jsLocalize } from '/@/multilang'
import { mdiMessageTextOutline } from '@mdi/js'

ftl`
  notification-bulletin = Bulletin
  `

let unSubscribe

function toastify(doc) {
  const timestamp = parseISO(doc.validity_start || doc.timestamp)
  return {
    uuid: doc.uuid,
    start: timestamp,
    title: translate('notification-bulletin').text,
    body: jsLocalize(parse_localized(doc.title)),
    subtitle: jsFormatDateTime({ dt: timestamp }),
    // discard the notification after the event has started
    expiry: parseISO(doc.validity_end),
    iconPath: mdiMessageTextOutline,
    requireInteraction: false,
  }
}

function bulletin(notify, denotify) {
  unSubscribe?.()
  unSubscribe = subscription({
    query: `subscription { bbsNotifications { operation, document { uuid, title, timestamp, validity_start, validity_end } } }`,
    requestPolicy: 'cache-and-network',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.bbsNotifications) return

    const operation = e.data.bbsNotifications.operation
    const doc = e.data.bbsNotifications.document

    const toast = toastify({ ...doc, uuid: `bulletin#${doc.uuid}` })

    if (operation == 'delete') denotify(toast)
    else notify(toast)
  })

  return () => {
    unSubscribe?.()
  }
}

export { bulletin }
