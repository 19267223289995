import { negotiateLanguages } from '@fluent/langneg'
import { fromPairs, isObjectLike, keys, toPairs } from 'lodash-es'
import { derived } from 'svelte/store'

import { settings } from '../store.js'
import { jsLocale, locale, mapLanguage, translate } from './i18n.js'

export function jsLocalize(label, lang = jsLocale()) {
  if (!isObjectLike(label)) return label

  label = fromPairs(toPairs(label).map((pp) => [mapLanguage(pp[0]), pp[1]]))
  lang = negotiateLanguages(
    [mapLanguage(lang), jsLocale(), settings.defaultLocale],
    keys(label)
  )

  for (let ll of [...lang, '']) {
    if (label[ll]) return label[ll]
  }
  for (var i in label) {
    // eslint-disable-next-line no-prototype-builtins
    if (label.hasOwnProperty(i) && typeof i !== 'function') return label[i]
  }
  if (label['ftl']) {
    return translate(label['ftl']).text
  }
}

export const localize = derived(locale, ($locale) => (aa) =>
  jsLocalize(aa, $locale) || ''
)

export const parse_localized = (ss) => {
  if (ss?.[0] == '{')
    return fromPairs(
      toPairs(JSON.parse(ss)).map((pp) => [mapLanguage(pp[0]), pp[1]])
    )
  return { eng: ss }
}

const collators = {}
const getCollator = (locale) =>
  collators[locale] || (collators[locale] = new Intl.Collator(locale))

export const localeCompare = (a, b, locale = jsLocale()) =>
  getCollator(locale).compare(a, b)
