<script>
  export let component

  function _props(pp) {
    let { component, ...rest } = $$props
    return rest
  }
  $: props = _props($$props)
</script>

{#await component.then ? component : component()}
  Loading component...
{:then Cmp}
  <svelte:component this="{Cmp.default}" {...props} />
{/await}
