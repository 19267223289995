import { subscription } from '@urql/svelte'
import { jsFormatDateTime } from '/@/multilang/i18n.js'
import { translate, ftl } from '/@/multilang'
import { parseISO } from '/@/utils.js'
import { mdiCalendar } from '@mdi/js'
import { router } from '/@/router.js'

ftl`
  notification-event = event
  `

let unsubscribeScope, unsubscribeInmate

function toastify(evt, idPrefix) {
  const dt = parseISO(evt.start)
  return {
    id: `${idPrefix}#${evt.uuid}`,
    start: dt,
    title: translate('notification-event').text,
    body: evt.title,
    subtitle: jsFormatDateTime({ dt }),
    iconPath: mdiCalendar,
    requireInteraction: false,
    url: `/calendar/?eid=${evt.uuid}`,
  }
}

const queryEventFields = 'uuid, start, title, createdByStaff'

/**
 * Calls back on (scope) event updates
 * @param {function} notify
 * @param {function} denotify
 * @returns
 */
function eventScope(notify, denotify) {
  unsubscribeScope?.()
  unsubscribeScope = subscription({
    query: `subscription{ calendarUpdatesScope{ operation, event{ ${queryEventFields} } } }`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.calendarUpdatesScope) return

    const evt = e.data.calendarUpdatesScope.event
    // evt.title = translate('notification-eventScope').text

    // create or update
    if (
      !['initial', 'delete'].includes(e.data.calendarUpdatesScope.operation) &&
      !(parseISO(evt?.start) < new Date())
    ) {
      notify(toastify(evt, 'eventScope'))
    } else {
      denotify(evt)
    }
  })

  return () => {
    unsubscribeScope?.()
  }
}

/**
 * Calls back on (inmate) event updates
 * @param {function} notify
 * @param {function} denotify
 * @returns
 */
function eventInmate(notify, denotify) {
  unsubscribeInmate?.()
  unsubscribeInmate = subscription({
    query: `subscription{ calendarUpdatesInmate{ operation, event{ ${queryEventFields} } } }`,
    requestPolicy: 'network-only',
  }).subscribe((e) => {
    if (e.error) return console.error(e)
    if (!e?.data?.calendarUpdatesInmate) return

    const evt = e.data.calendarUpdatesInmate.event
    // evt.title = translate('notification-eventInmate').text

    // no notification if the change comes from current user
    if (!evt.createdByStaff && router.url().endsWith('calendar')) return

    // create or update
    if (
      !['initial', 'delete'].includes(e.data.calendarUpdatesInmate.operation) &&
      !(parseISO(evt?.start) < new Date())
    ) {
      notify(toastify(evt, 'eventInmate'))
    } else {
      denotify(evt)
    }
  })

  return () => {
    unsubscribeInmate?.()
  }
}

export { eventScope, eventInmate }
