import { ftl } from '/@/multilang'
import { settings } from '../../store'

import Notification from './Notification.svelte'

import { eventReminder } from './eventReminder.js'
import { eventScope, eventInmate } from './event.js'
import { chatMessage } from './chatMessage.js'
import { mail, mailPendingReview } from './mail.js'
import { request, requestPending } from './request.js'
import { invitation } from './invitation.js'
import { bulletin } from './bulletin.js'
import { callIncoming, callPending } from './call.js'
import { pendingContact } from './pendingContact.js'

ftl`
  notification-setting-chatmessage = Chat
  notification-setting-event-scope = Event Scope
  notification-setting-event-inmate = Event Inmate
  notification-setting-event-reminder = Event reminder
  notification-setting-mail = New mail
  notification-setting-mail-pendingReview = Mail pending
  notification-setting-request = Request
  notification-setting-request-pending = Request pending
  notification-setting-invitation = Invitation pending
  notification-setting-bulletin = Bulletin
  notification-setting-call = Call Incoming
  notification-setting-callPending = Call pending
  notification-setting-pendingcontact = Contact pending

  notification-setting-none = None
  notification-setting-toast-sound = Banner with sound
  notification-setting-toast-silent = Silent banner

  staff-emailnotification-request-subject = New request
  staff-emailnotification-request-body =
    Hello,

    there is a new request pending on {$uri}.

    Regards
    {$site}
  staff-emailnotification-mail-subject = New mail
  staff-emailnotification-mail-body =
    Hello,

    there is a new mail pending on {$uri}.

    Regards
    {$site}
  staff-emailnotification-chat-subject = New chat message
  staff-emailnotification-chat-body =
    Hello,

    there is a new chat message on {$uri}.

    Regards
    {$site}
  staff-emailnotification-test-subject = Test notification
  staff-emailnotification-test-body =
    Hello,

    this just a test notification {$uri}.

    Regards
    {$site}
  system-scrubbed-email-subject = Scrubbed attachment
  system-scrubbed-email-text =
    The email attachment you sent was programmatically deleted for security reasons and the recipient did not receive it.

    You can send attachments of up to 20 megabytes to a prison e-mail.

    - PDF/A files
    - Text files (.txt)
    - MS Office files, and
    - LibreOffice files

    Regards
    postmaster@{$domain}
  `

const notificationOptions = [
  { text: 'notification-setting-none', value: 'none' },
  { text: 'notification-setting-toast-sound', value: 'toast' },
  { text: 'notification-setting-toast-silent', value: 'toast_silent' },
]

const notificationDefaultOption = notificationOptions[1].value

// TODO rename this
const metas = {
  chatMessage: {
    name: 'chatMessage',
    permission: 'chat',
    text: 'notification-setting-chatmessage',
    subscribe: chatMessage,
    value: notificationDefaultOption,
  },
  eventScope: {
    name: 'event-scope',
    permission: 'events',
    text: 'notification-setting-event-scope',
    subscribe: eventScope,
    value: notificationDefaultOption,
  },
  eventInmate: {
    name: 'event-inmate',
    permission: 'events',
    text: 'notification-setting-event-inmate',
    subscribe: eventInmate,
    value: notificationDefaultOption,
  },
  eventReminder: {
    name: 'eventReminder',
    permission: 'events',
    text: 'notification-setting-event-reminder',
    subscribe: eventReminder,
    value: notificationDefaultOption,
  },
  mail: {
    name: 'mail',
    permission: 'mail',
    text: 'notification-setting-mail',
    subscribe: mail,
    value: notificationDefaultOption,
  },
  mailPendingReview: {
    name: 'mailPendingReview',
    permission: 'mail',
    text: 'notification-setting-mail-pendingReview',
    subscribe: mailPendingReview,
    value: notificationDefaultOption,
  },
  request: {
    name: 'request',
    permission: 'requests',
    text: 'notification-setting-request',
    subscribe: request,
    value: notificationDefaultOption,
  },
  requestPending: {
    name: 'requestPending',
    permission: 'requests',
    text: 'notification-setting-request-pending',
    subscribe: requestPending,
    value: notificationDefaultOption,
  },
  invitation: {
    name: 'invitation',
    permission: 'events',
    text: 'notification-setting-invitation',
    subscribe: invitation,
    value: notificationDefaultOption,
  },
  bulletin: {
    name: 'bulletin',
    permission: 'bulletinboard',
    text: 'notification-setting-bulletin',
    subscribe: bulletin,
    value: notificationDefaultOption,
  },
  callIncoming: {
    name: 'callIncoming',
    permission: null,
    text: 'notification-setting-call',
    subscribe: callIncoming,
    value: notificationDefaultOption,
  },
  callPending: {
    name: 'callPending',
    permission: null,
    text: 'notification-setting-callPending',
    subscribe: callPending,
    value: notificationDefaultOption,
  },
  contactPending: {
    name: 'pendingContact',
    permission: 'inmate_masterdata',
    text: 'notification-setting-pendingcontact',
    subscribe: pendingContact,
    value: notificationDefaultOption,
  },
}

// It has to be a function because the settings.context, despite quite constant, is only set in the frontend/App.svelte
const getAvailableNotifications = () =>
  ({
    staff: [
      metas.chatMessage,
      metas.mailPendingReview,
      metas.requestPending,
      metas.callIncoming,
      metas.callPending,
      metas.contactPending,
    ],
    inmate: [
      metas.bulletin,
      metas.eventReminder,
      metas.eventScope,
      metas.eventInmate,
      metas.chatMessage,
      metas.mail,
      metas.request,
      metas.invitation,
      metas.callIncoming,
    ],

    visitor: [],

    external: [metas.mail],

    internal: [],
  }[settings.context])

export { Notification, notificationOptions, getAvailableNotifications }
