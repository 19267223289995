<script context="module">
  import { writable } from 'svelte/store'

  export const breadcrumbs = createStore()

  function createStore() {
    let data = []

    let { subscribe, set } = writable(data)

    return {
      subscribe,
      add(obj) {
        data.push(obj)
        set(data)
      },
      remove(obj) {
        data = data.filter((o) => o !== obj)
        set(data)
      },
      update() {
        set(data)
      },
    }
  }
</script>

<script>
  import Icon from 'mdi-svelte'
</script>

<div class="flex flex-row text-sm mb-2">
  {#each $breadcrumbs as bc, i}
    {#if i != 0}&bull;{/if}
    <div class="flex-none px-1">
      <div class="flex flex-row items-center">
        {#if bc.icon}
          <div class="pr-1">
            <Icon path="{bc.icon}" size="16px" />
          </div>
        {/if}
        {#if i + 1 < $breadcrumbs.length}
          <a href="{bc.url}">{bc.label}</a>
        {:else}
          <div class="cursor-default">{bc.label}</div>
        {/if}
      </div>
    </div>
  {/each}
</div>
